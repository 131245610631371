module.exports = {
  generateAttributes: function (geometryType) {
    return {
      fill: null,
      stroke: null,
      blending: null,
      aggregation: {},
      labels: {}
    };
  }
};
