const Backbone = require('backbone');

// TODO: Maybe this file is unnecessary

const SlideTransition = Backbone.Model.extend({
  defaults: {
    time: 0
  }
});

module.exports = SlideTransition;
