module.exports = {
  green: {
    '3': ['#E5F5F9', '#99D8C9', '#2CA25F'],
    '4': ['#EDF8FB', '#B2E2E2', '#66C2A4', '#238B45'],
    '5': ['#EDF8FB', '#B2E2E2', '#66C2A4', '#2CA25F', '#006D2C'],
    '6': ['#EDF8FB', '#CCECE6', '#99D8C9', '#66C2A4', '#2CA25F', '#006D2C'],
    '7': ['#EDF8FB', '#D7FAF4', '#CCECE6', '#66C2A4', '#41AE76', '#238B45', '#005824']
  },
  blue: {
    '3': ['#EDF8B1', '#7FCDBB', '#2C7FB8'],
    '4': ['#FFFFCC', '#A1DAB4', '#41B6C4', '#225EA8'],
    '5': ['#FFFFCC', '#A1DAB4', '#41B6C4', '#2C7FB8', '#253494'],
    '6': ['#FFFFCC', '#C7E9B4', '#7FCDBB', '#41B6C4', '#2C7FB8', '#253494'],
    '7': ['#FFFFCC', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0', '#225EA8', '#0C2C84']
  },
  pink: {
    '3': ['#E7E1EF', '#C994C7', '#DD1C77'],
    '4': ['#F1EEF6', '#D7B5D8', '#DF65B0', '#CE1256'],
    '5': ['#F1EEF6', '#D7B5D8', '#DF65B0', '#DD1C77', '#980043'],
    '6': ['#F1EEF6', '#D4B9DA', '#C994C7', '#DF65B0', '#DD1C77', '#980043'],
    '7': ['#F1EEF6', '#D4B9DA', '#C994C7', '#DF65B0', '#E7298A', '#CE1256', '#91003F']
  },
  black: {
    '3': ['#F0F0F0', '#BDBDBD', '#636363'],
    '4': ['#F7F7F7', '#CCCCCC', '#969696', '#525252'],
    '5': ['#F7F7F7', '#CCCCCC', '#969696', '#636363', '#252525'],
    '6': ['#F7F7F7', '#D9D9D9', '#BDBDBD', '#969696', '#636363', '#252525'],
    '7': ['#F7F7F7', '#D9D9D9', '#BDBDBD', '#969696', '#737373', '#525252', '#252525']
  },
  red: {
    '3': ['#FFEDA0', '#FEB24C', '#F03B20'],
    '4': ['#FFFFB2', '#FECC5C', '#FD8D3C', '#E31A1C'],
    '5': ['#FFFFB2', '#FECC5C', '#FD8D3C', '#F03B20', '#BD0026'],
    '6': ['#FFFFB2', '#FED976', '#FEB24C', '#FD8D3C', '#F03B20', '#BD0026'],
    '7': ['#FFFFB2', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#B10026']
  },
  inverted_green: {
    '3': ['#2CA25F', '#99D8C9', '#E5F5F9'],
    '4': ['#238B45', '#66C2A4', '#B2E2E2', '#EDF8FB'],
    '5': ['#006D2C', '#2CA25F', '#66C2A4', '#B2E2E2', '#EDF8FB'],
    '6': ['#006D2C', '#2CA25F', '#66C2A4', '#99D8C9', '#CCECE6', '#EDF8FB'],
    '7': ['#005824', '#238B45', '#41AE76', '#66C2A4', '#CCECE6', '#D7FAF4', '#EDF8FB']
  },
  inverted_blue: {
    '3': ['#2C7FB8', '#7FCDBB', '#EDF8B1'],
    '4': ['#225EA8', '#41B6C4', '#A1DAB4', '#FFFFCC'],
    '5': ['#253494', '#2C7FB8', '#41B6C4', '#A1DAB4', '#FFFFCC'],
    '6': ['#253494', '#2C7FB8', '#41B6C4', '#7FCDBB', '#C7E9B4', '#FFFFCC'],
    '7': ['#0C2C84', '#225EA8', '#1D91C0', '#41B6C4', '#7FCDBB', '#C7E9B4', '#FFFFCC']
  },
  inverted_pink: {
    '3': ['#DD1C77', '#C994C7', '#E7E1EF'],
    '4': ['#CE1256', '#DF65B0', '#D7B5D8', '#F1EEF6'],
    '5': ['#980043', '#DD1C77', '#DF65B0', '#D7B5D8', '#F1EEF6'],
    '6': ['#980043', '#DD1C77', '#DF65B0', '#C994C7', '#D4B9DA', '#F1EEF6'],
    '7': ['#91003F', '#CE1256', '#E7298A', '#DF65B0', '#C994C7', '#D4B9DA', '#F1EEF6']
  },
  inverted_black: {
    '3': ['#636363', '#BDBDBD', '#F0F0F0'],
    '4': ['#525252', '#969696', '#CCCCCC', '#F7F7F7'],
    '5': ['#252525', '#636363', '#969696', '#CCCCCC', '#F7F7F7'],
    '6': ['#252525', '#636363', '#969696', '#BDBDBD', '#D9D9D9', '#F7F7F7'],
    '7': ['#252525', '#525252', '#737373', '#969696', '#BDBDBD', '#D9D9D9', '#F7F7F7']
  },
  inverted_red: {
    '3': ['#F03B20', '#FEB24C', '#FFEDA0'],
    '4': ['#E31A1C', '#FD8D3C', '#FECC5C', '#FFFFB2'],
    '5': ['#BD0026', '#F03B20', '#FD8D3C', '#FECC5C', '#FFFFB2'],
    '6': ['#BD0026', '#F03B20', '#FD8D3C', '#FEB24C', '#FED976', '#FFFFB2'],
    '7': ['#B10026', '#E31A1C', '#FC4E2A', '#FD8D3C', '#FEB24C', '#FED976', '#FFFFB2']
  },
  spectrum1: {
    '3': ['#1a9850', '#fff2cc', '#d73027'],
    '4': ['#1a9850', '#d2ecb4', '#fed6b0', '#d73027'],
    '5': ['#1a9850', '#8cce8a', '#fff2cc', '#f79272', '#d73027'],
    '6': ['#1a9850', '#8cce8a', '#d2ecb4', '#fed6b0', '#f79272', '#d73027'],
    '7': ['#1a9850', '#8cce8a', '#d2ecb4', '#fff2cc', '#fed6b0', '#f79272', '#d73027']
  },
  spectrum2: {
    '3': ['#0080ff', '#fff2cc', '#ff4d4d'],
    '4': ['#0080ff', '#7fbfff', '#ffa6a6', '#ff4d4d'],
    '5': ['#0080ff', '#40a0ff', '#fff2cc', '#ff7a7a', '#ff4d4d'],
    '6': ['#0080ff', '#40a0ff', '#7fbfff', '#ffa6a6', '#ff7a7a', '#ff4d4d'],
    '7': ['#0080ff', '#40a0ff', '#7fbfff', '#fff2cc', '#ffa6a6', '#ff7a7a', '#ff4d4d']
  },
  purple_states: {
    '3': ['#F1E6F1', '#B379B3', '#8A4E8A'],
    '4': ['#F1E6F1', '#D8BBD8', '#A05AA0', '#8A4E8A'],
    '5': ['#F1E6F1', '#D8BBD8', '#B379B3', '#A05AA0', '#8A4E8A'],
    '6': ['#F1E6F1', '#D8BBD8', '#CCA5CC', '#B379B3', '#A05AA0', '#8A4E8A'],
    '7': ['#F1E6F1', '#D8BBD8', '#CCA5CC', '#C08FC0', '#B379B3', '#A05AA0', '#8A4E8A']
  },
  red_states: {
    '3': ['#F2D2D3', '#D4686C', '#C1373C'],
    '4': ['#F2D2D3', '#EBB7B9', '#CC4E52', '#C1373C'],
    '5': ['#F2D2D3', '#EBB7B9', '#D4686C', '#CC4E52', '#C1373C'],
    '6': ['#F2D2D3', '#EBB7B9', '#E39D9F', '#D4686C', '#CC4E52', '#C1373C'],
    '7': ['#F2D2D3', '#EBB7B9', '#E39D9F', '#DB8286', '#D4686C', '#CC4E52', '#C1373C']
  },
  blue_states: {
    '3': ['#ECF0F6', '#6182B5', '#43618F'],
    '4': ['#ECF0F6', '#B2C2DB', '#4E71A6', '#43618F'],
    '5': ['#ECF0F6', '#B2C2DB', '#6182B5', '#4E71A6', '#43618F'],
    '6': ['#ECF0F6', '#B2C2DB', '#9BB0D0', '#6182B5', '#4E71A6', '#43618F'],
    '7': ['#ECF0F6', '#B2C2DB', '#9BB0D0', '#849EC5', '#6182B5', '#4E71A6', '#43618F']
  },
  inverted_purple_states: {
    '3': ['#8A4E8A', '#B379B3', '#F1E6F1'],
    '4': ['#8A4E8A', '#A05AA0', '#D8BBD8', '#F1E6F1'],
    '5': ['#8A4E8A', '#A05AA0', '#B379B3', '#D8BBD8', '#F1E6F1'],
    '6': ['#8A4E8A', '#A05AA0', '#B379B3', '#CCA5CC', '#D8BBD8', '#F1E6F1'],
    '7': ['#8A4E8A', '#A05AA0', '#B379B3', '#C08FC0', '#CCA5CC', '#D8BBD8', '#F1E6F1']
  },
  inverted_red_states: {
    '3': ['#C1373C', '#D4686C', '#F2D2D3'],
    '4': ['#C1373C', '#CC4E52', '#EBB7B9', '#F2D2D3'],
    '5': ['#C1373C', '#CC4E52', '#D4686C', '#EBB7B9', '#F2D2D3'],
    '6': ['#C1373C', '#CC4E52', '#D4686C', '#E39D9F', '#EBB7B9', '#F2D2D3'],
    '7': ['#C1373C', '#CC4E52', '#D4686C', '#DB8286', '#E39D9F', '#EBB7B9', '#F2D2D3']
  },
  inverted_blue_states: {
    '3': ['#43618F', '#6182B5', '#ECF0F6'],
    '4': ['#43618F', '#4E71A6', '#B2C2DB', '#ECF0F6'],
    '5': ['#43618F', '#4E71A6', '#6182B5', '#B2C2DB', '#ECF0F6'],
    '6': ['#43618F', '#4E71A6', '#6182B5', '#9BB0D0', '#B2C2DB', '#ECF0F6'],
    '7': ['#43618F', '#4E71A6', '#6182B5', '#849EC5', '#9BB0D0', '#B2C2DB', '#ECF0F6']
  }
};
